import React from "react";
import {
  useQuery,
  useMutation,
} from "@apollo/client";
import Main from "../components/main";
import {getCurrentUserQuery, loginMutation} from "../components/logic/user";
import Layout from "../components/section/layout";

const Logout = (props) => {
  return (<Main>
    <Layout>
      <UserCheck {...props} />
    </Layout>
  </Main>)
}

const UserCheck = (props) => {
  const currentUserQuery = useQuery(getCurrentUserQuery);
  const [logout] = useMutation(loginMutation);
  if (currentUserQuery.loading) {
    return <React.Fragment/>
  }

  const user = currentUserQuery?.data?.classMethods?.User?.getCurrentUser;


  if (!user) {
    return window.location = "/";
  }
  return logout();
}

export default Logout;
